@import '../../scss/theme-bootstrap';

.elc-orderable-filters-wrapper {
  min-height: 70px;
  width: 100%;
  background: transparent;
  @include breakpoint($bp--medium-up) {
    padding-top: 20px;
    position: inherit;
  }
  &.filters-applied {
    margin-bottom: 25px;
  }
  & ~ .elc-sort-wrapper {
    position: absolute;
    top: 0;
    @include breakpoint($bp--medium-up) {
      top: 20px;
    }
  }
}

.elc-collapsible-wrapper {
  position: relative;
  display: flex;
  background: $color--white;
  z-index: 2;
  width: 100%;
  .elc-product-grid--filter-results-count-wrapper {
    position: relative;
    top: unset;
    line-height: 45px;
    display: flex;
    flex-direction: column;
    width: auto;
    margin-#{$ldirection}: auto;
    padding: 0 20px;
    justify-content: center;
  }
}
.elc-product-grid--filters-top-section,
.elc-product-grid--filters-bottom-section,
.elc-responsive-modal {
  .elc-overlay-wrapper-close-button,
  .elc-product-grid--clear-all {
    position: relative;
    margin: 0;
    .elc-remove-icon {
      position: absolute;
      top: 15px;
      #{$rdirection}: 20px;
      width: 10px;
      height: 10px;
    }
  }
  .elc-overlay-wrapper-close-button {
    @include body-text--small-bold;
    background: $color--white;
    color: $color--black;
    border: 1px solid $color-cta-grey;
    border-radius: 100px;
    height: 45px;
    padding-#{$rdirection}: 40px;
    width: fit-content;
    &:hover {
      background: $color--white;
    }
  }
  .elc-product-grid--clear-all,
  .elc-product-grid--apply-filter {
    @include body-text--small-bold;
    width: fit-content;
    border: 1px solid $color--black;
    border-radius: 100px;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 45px;
    padding: 0 15px;
    @include swap_direction(margin, 0 0 0 10px);
    height: 45px;
    z-index: 2;
  }
  .elc-product-grid--clear-all {
    @include swap_direction(padding, 0 35px 0 15px);
    background: transparent;
    color: $color--black;
    &:hover {
      background: transparent;
    }
  }
  .elc-filters-label {
    @include body-text--small-bold;
    line-height: 25px;
  }
  .elc-product-grid {
    &--apply-filter {
      background: $color--black;
      &:hover {
        background: $color--black;
      }
    }
    &--apply-filter-label {
      @include body-text--small-bold;
      color: $color--white;
    }
  }
}
.elc-product-grid--filters-top-section {
  .elc-product-grid--filters-modal-button {
    position: absolute;
    height: 45px;
    border: 1px solid $color-cta-grey;
    border-radius: 100px;
    padding-top: 12px;
    display: flex;
    margin: 0;
    width: auto;
    max-width: 180px;
    &:hover {
      background: transparent;
    }
  }
  .elc-filter-icon {
    @include swap_direction(margin, 3px 2px 0 5px);
    width: 20px;
    height: 17px;
  }
  .elc-applied-filters-number {
    display: none;
  }
  .elc-collapsible-wrapper {
    margin: 0 0 20px;
    padding: 0 0 20px;
    border-bottom: 1px solid $color-cta-grey;
    .elc-product-grid--clear-all {
      border: 1px solid $color-cta-grey;
    }
  }
}
.elc-product-grid--filters-bottom-section {
  .elc-product-grid--filter-results-count-wrapper {
    display: none;
    .filters-applied & {
      display: flex;
      padding: 0;
      width: auto;
      margin-#{$ldirection}: auto;
    }
  }
  .elc-product-grid--clear-all {
    height: 22px;
    line-height: 19px;
    margin: 9px 0 0;
    color: $color--white;
    background-color: $color--black;
    padding: 0 10px;
    &:hover {
      color: $color--white;
      background-color: $color--black;
    }
    .elc-remove-icon {
      display: none;
    }
  }
  .elc-filter-results-count-wrapper {
    height: 22px;
    margin-top: 7px;
    white-space: nowrap;
  }
  .elc-product-grid--selected-filter-values-wrapper {
    width: auto;
    display: flex;
    .elc-button {
      @include body-text--small-bold;
      background: transparent;
      color: $color--black;
      padding: 0 10px;
      height: auto;
      border: 1px solid black;
      border-radius: 100px;
      line-height: 19px;
      &:hover {
        background: transparent;
      }
    }
    .elc-remove-icon {
      width: 8px;
      height: 8px;
    }
  }
}

.elc-product-grid {
  &--header {
    padding: 0 20px;
  }
  &--filters-modal-button {
    position: absolute;
    height: 45px;
    border: 1px solid black;
    border-radius: 100px;
    padding-top: 12px;
    &:hover {
      background: transparent;
    }
  }
  &--filters-top-section,
  &--filters-bottom-section {
    display: flex;
  }
  &--filters-bottom-section {
    margin-top: 55px;
    position: absolute;
    width: 100%;
  }
  &--filter-results-count-label {
    @include body-text;
    align-self: center;
    margin-#{$ldirection}: auto;
  }
  &--filters-overlay-section {
    width: 100%;
    position: absolute;
    #{$ldirection}: 0;
    z-index: 12;
    &:after {
      content: '';
      background: $color--black;
      position: absolute;
      height: 5000%;
      width: 200%;
      #{$ldirection}: -50%;
      opacity: 0.2;
      z-index: 11;
    }
    .elc-overlay-wrapper-close-button {
      display: none;
    }
    .elc-collapsible-wrapper {
      padding: 0 0 20px;
    }
  }
}

.elc-mobile-filters-modal,
.sd-product-grid {
  .elc-product-grid--filter-options-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    padding: 10px 0 20px;
    @include breakpoint($bp--medium-up) {
      padding: 20px 0;
    }
    .elc-checkbox-label {
      @include body-text;
      white-space: nowrap;
      text-transform: capitalize;
    }
    .elc-filter-option {
      &--red_color {
        @include paint-sd-checkbox($color-red-shade, $color-red-shade, $color--white);
      }
      &--pink_color {
        @include paint-sd-checkbox($color-pink-shade, $color-pink-shade, $color--white);
      }
      &--orange_color {
        @include paint-sd-checkbox($color-orange-shade, $color-orange-shade, $color--white);
      }
      &--nude_color {
        @include paint-sd-checkbox($color-nude-shade, $color-nude-shade, $color--white);
      }
      &--berry_color {
        @include paint-sd-checkbox($color-berry-shade, $color-berry-shade, $color--white);
      }
      &--blue_color {
        @include paint-sd-checkbox($color-blue-shade, $color-blue-shade, $color--white);
      }
      &--purple_color {
        @include paint-sd-checkbox($color-purple-shade, $color-purple-shade, $color--white);
      }
      &--brown_color {
        @include paint-sd-checkbox($color-brown-shade, $color-brown-shade, $color--white);
      }
      &--bold_color {
        @include paint-sd-checkbox($color-bold-shade, $color-bold-shade, $color--white);
      }
      &--coral_color {
        @include paint-sd-checkbox($color-coral-shade, $color-coral-shade, $color--white);
      }
      &--beige_color {
        @include paint-sd-checkbox($color-beige-shade, $color-beige-shade, $color--white);
      }
      &--copper_color {
        @include paint-sd-checkbox($color-copper-shade, $color-copper-shade, $color--white);
      }
      &--gold_color {
        @include paint-sd-checkbox($color-gold-shade, $color-gold-shade, $color--white);
      }
      &--white_color {
        @include paint-sd-checkbox($color-white-shade, $color-white-shade, $color--white);
      }
      &--neutral_color {
        @include paint-sd-checkbox($color-neutral-shade, $color-neutral-shade, $color--white);
      }
      &--yellow_color {
        @include paint-sd-checkbox($color-yellow-shade, $color-yellow-shade, $color--white);
      }
      &--bronze_color {
        @include paint-sd-checkbox($color-bronze-shade, $color-bronze-shade, $color--white);
      }
      &--green_color {
        @include paint-sd-checkbox($color-green-shade, $color-green-shade, $color--white);
      }
      &--silver_color {
        @include paint-sd-checkbox($color-silver-shade, $color-silver-shade, $color--white);
      }
      &--black_color {
        @include paint-sd-checkbox($color-black-shade, $color-black-shade, $color--white);
      }
    }
    .elc-checkbox-icon {
      background-color: $color--gray--lighter;
      width: 20px;
    }
    .elc-checkbox-selected {
      .elc-checkbox-icon {
        background-color: $color--black;
      }
    }
  }
  .elc-product-grid--filter-option-checkbox {
    display: flex;
  }
  .elc-product-grid--filter-set-wrapper {
    min-width: 200px;
    padding: 0;
    width: 100%;
    display: inline-block;
    .elc-button.elc-product-grid--filter-set {
      font-size: 12px;
    }
    @include breakpoint($bp--medium-up) {
      padding-#{$rdirection}: 50px;
      width: unset;
    }
    label {
      @include body-text--small-bold;
      display: flex;
      text-transform: uppercase;
      letter-spacing: normal;
    }
    .elc-styled-filters-active-filters-no {
      display: none;
    }
    .elc-product-grid--filter-set {
      @include body-text--bold;
      background: transparent;
      width: 100%;
      text-align: $ldirection;
      border-top: 1px solid $color-cta-grey;
      position: relative;
      text-transform: uppercase;
      @include breakpoint($bp--medium-up) {
        border: none;
      }
      &:after {
        content: '';
        background: url('#{$base-theme-path}svg-icons/src/icon--chevron-down.svg');
        background-size: cover;
        width: 10px;
        height: 9px;
        transform: rotate(-90deg);
        display: inline-block;
        position: absolute;
        #{$rdirection}: 25px;
        top: 17px;
        @include breakpoint($bp--medium-up) {
          display: none;
        }
      }
      &.active {
        &:after {
          transform: none;
        }
      }
    }
  }
}

.elc-responsive-modal-mask {
  &.isOpen {
    background-color: $color--black--opacity;
  }
  .elc-overlay-wrapper-close-button {
    margin: 0 20px 20px;
  }
  .elc-product-grid--clear-all {
    position: fixed;
    bottom: 20px;
    margin: 0;
    padding: 0;
    #{$ldirection}: 20px;
    width: calc(50% - 22px);
    border-color: $color--white;
    color: $color--white;
    height: 46px;
    top: auto;
  }
  .elc-product-grid--apply-filter {
    position: fixed;
    bottom: 20px;
    #{$rdirection}: 50%;
    margin-#{$rdirection}: -25%;
    width: 50%;
    border-color: $color--white;
    background-color: $color--white;
    .elc-product-grid--apply-filter-label {
      color: $color--black;
    }
  }
  .elc-product-grid--clear-all ~ .elc-product-grid--apply-filter {
    #{$rdirection}: 20px;
    width: calc(50% - 22px);
    margin-#{$rdirection}: 0;
  }
  .elc-product-grid--filter-options-wrapper {
    padding: 10px 20px 20px;
  }
  .elc-filter-results-count-wrapper {
    width: auto;
    margin-#{$ldirection}: auto;
    padding-top: 10px;
    position: absolute;
    #{$rdirection}: 20px;
  }
  .elc-responsive-modal {
    transform: none;
    #{$ldirection}: 0;
    padding: 20px 0 135px;
    top: 0;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    overflow: scroll;
    align-content: flex-start;
    position: absolute;
    &:after {
      content: '';
      background-color: $color--black;
      height: 85px;
      width: 100%;
      position: fixed;
      z-index: 1;
      bottom: 0;
    }
    @include breakpoint($bp--medium-up) {
      flex-direction: column;
    }
  }
}
